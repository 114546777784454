<div>
  <header class="header" wmAnimate="landing" aos speed="normal">
    <a href="" class="logo">
      <span class="grey-color"> &lt;</span>
      <span class="logo-name">{{greeting.username}}</span>
      <span class="grey-color">/&gt;</span>
    </a>
    <input class="menu-btn" type="checkbox" id="menu-btn" />
    <label class="menu-icon" for="menu-btn">
      <span class="navicon"></span>
    </label>
    <ul class="menu">
      <li>
        <a href="#skills">About</a>
      </li>
      <li>
        <a href="#skills">Skills</a>
      </li>
      <li>
        <a href="#education">Education</a>
      </li>
      <li>
        <a href="#projects">Projects</a>
      </li>
      <li>
        <a href="#experience">Experiences</a>
      </li>
      <li>
        <a href="#contact">Contact Me</a>
      </li>
    </ul>
  </header>
</div>
