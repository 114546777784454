
<div class="greet-main" id="greeting">
  <div class="greeting-main"  wmAnimate="fadeInUp" aos speed="normal">
    <div class="greeting-text-div">
      <div>
        <h1 class="greeting-text">
          {{greeting.title}}
          <span class="wave-emoji">👋</span>
        </h1>
        <p class="greeting-text-p subTitle">{{greeting.subTitle}}</p>
            <app-social-media></app-social-media>
        <div class="button-greeting-div">
<!--        button-->
          <div>
            <a class="main-button" href="#contact">Contact me</a>
          </div>
          <div>
            <a class="main-button" href={{greeting.resumeLink}}>See my resume</a>
          </div>
<!--          <Button text="Contact me" href="#contact" />-->
<!--          <Button text="See my resume" newTab={true} href={greeting.resumeLink} />-->
        </div>
      </div>
    </div>
    <div class="greeting-image-div">
      <img src="../../assets/images/manOnTable.svg" alt="Sarthak sitting on table">
    </div>
  </div>
</div>
