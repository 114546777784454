<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.8/css/all.css" integrity="sha384-3AB7yXWz4OeoZcPbieVW64vVXEwADiYyAEhwilzWsLw+9FgqpyjjStpPnpBO8o8S" crossorigin="anonymous">
<app-header></app-header>
<app-greeting></app-greeting>
<app-skills></app-skills>
<app-education></app-education>
<app-skill-progress></app-skill-progress>
<app-projects></app-projects>
<app-experience></app-experience>
<app-contact></app-contact>
<app-top-button></app-top-button>
<app-footer></app-footer>
<!--<app-github-calendar></app-github-calendar>-->
<router-outlet></router-outlet>

